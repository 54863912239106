import { lazyWithPreload as lazy } from "react-lazy-with-preload";
import * as URLS from "../utils/URLS";
import PerformanceMetrics from "../components/auto_recon/PerformanceMetrics";
import FailedStripeDeposits from "../components/operations/FailedStripeDeposits";
import RestrictedUserSessions from "../components/compliance/RestrictedLoginSessions";

const Login = lazy(() => import("../components/auth/Login"));

const Auth2fa = lazy(() => import("../components/auth/Auth2fa"));

const CompleteCognito = lazy(() => import("../components/auth/CompleteCognito"));

const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));

const PositionsWinterMute = lazy(() => import("../components/dashboard/PositionsWinterMute"));

const PositionsPaxos = lazy(() => import("../components/dashboard/PositionsPaxos"));

const Profile = lazy(() => import("../components/users/Profile"));

const LimitOrders = lazy(() => import("../components/reports/LimitOrders"));

const Legacy = lazy(() => import("../components/legacy/Legacy"));

const CashWithdraws = lazy(() => import("../components/reports/CashWithdraws"));

const CashDeposits = lazy(() => import("../components/reports/CashDeposits"));

const UserSessions = lazy(() => import("../components/users/Sessions"));

const UserIPWhitelist = lazy(() => import("../components/users/IPWhiteList"));

const Vendors = lazy(() => import("../components/vendor/Vendor"));

const Reports = lazy(() => import("../components/reports/Index"));

const TransactionReports = lazy(() =>
	import("../components/reports/Transactions")
);

const FiatWithdrawPending = lazy(() =>
	import("../components/operations/FiatWithdrawPending")
);

const CryptoWithdrawPending = lazy(() =>
	import("../components/operations/CryptoWithdrawPending")
);

const CashDepositsPending = lazy(() =>
	import("../components/operations/CashDepositsPending")
);

const Currency = lazy(() => import("../components/currency/Currency"));

const Coin = lazy(() => import("../components/coins/Coins"));

const CoinGroup = lazy(() => import("../components/coins/CoinGroup"));

const Users = lazy(() => import("../components/users/Users"));

const CryptoWithdrawDeclined = lazy(() =>
	import("../components/operations/CryptoWithdrawDeclined")
);

const CryptoWithdrawRefund = lazy(() =>
	import("../components/operations/CryptoWithdrawRefund")
);

const FiatWithdrawRefund = lazy(() =>
	import("../components/operations/FiatWithdrawRefund")
);

const UserFiatHistory = lazy(() =>
	import("../components/operations/UserFiatHistory")
);

const ApiSettings = lazy(() =>
	import("../components/connectivity/ApiSettings")
);

const MobileSettings = lazy(() =>
	import("../components/connectivity/MobileSettings")
);


const ApiSettingsToken = lazy(() =>
	import("../components/connectivity/ApiSettingsToken")
);

const AffiliatesPayouts = lazy(() =>
	import("../components/affiliates/AffiliatesPayouts")
);

const EarningPerTransaction = lazy(() =>
	import("../components/affiliates/EarningPerTransaction")
);

const EarningPerUser = lazy(() =>
	import("../components/affiliates/EarningPerUser")
);

const ReferralCommission = lazy(() =>
	import("../components/affiliates/ReferralCommission")
);

const UserAffiliateStatus = lazy(() =>
	import("../components/affiliates/UserAffiliateStatus")
);

const StaticAffiliateStatus = lazy(() =>
	import("../components/affiliates/StaticAffiliateStatus")
);

const ReferralCodes = lazy(() =>
	import("../components/affiliates/ReferralCodes")
);
const ReferralLinks = lazy(() =>
	import("../components/affiliates/ReferralLinks")
);

const VanityUrl = lazy(() => import("../components/affiliates/VanityUrl"));

const CryptoWithdraws = lazy(() =>
	import("../components/reports/CryptoWithdraws")
);

const CryptoDeposits = lazy(() =>
	import("../components/reports/CryptoDeposits")
);

const AccountTiers = lazy(() =>
	import("../components/system_config/AccountTiers")
);

const SpreadGroups = lazy(() =>
	import("../components/system_config/SpreadGroups")
);

const ManageWalletAddress = lazy(() =>
	import("../components/system_config/ManageWalletAddress")
);

const VirtualIBAN = lazy(() =>
	import("../components/system_config/VirtualIBAN")
);

const ManageDepositWalletAddress = lazy(() =>
	import("../components/system_config/ManageDepositWalletAddress")
);

const Fees = lazy(() => import("../components/users/Fees"));

const CustomEmail = lazy(() => import("../components/users/CustomEmail"));

const UserEventEmails = lazy(() =>
	import("../components/users/UserEventEmails")
);

const AutoTrade = lazy(() => import("../components/users/AutoTrade"));

const ManageCompanies = lazy(() =>
	import("../components/compliance/ManageCompanies")
);
const ComplianceReport = lazy(() =>
	import("../components/compliance/ComplianceReport")
);
const ComplianceKytRejected = lazy(() =>
	import("../components/compliance/KytRejected")
);
const ComplianceB2b = lazy(() =>
	import("../components/compliance/B2b")
);
const ComplianceOver60Countdown = lazy(() =>
	import("../components/compliance/Over60Countdown")
);
const ComplianceTravelRule = lazy(() =>
	import("../components/compliance/travel_rule")
);

const ClientValuationStatement = lazy(() =>
	import("../components/compliance/ClientValuationStatement")
);

const WalletAddressGenerate = lazy(() =>
	import("../components/users/WalletAddressGenerate")
);

const KycBillings = lazy(() => import("../components/compliance/KycBillings"));

const Edd = lazy(() => import("../components/compliance/Edd"));

const Kyc = lazy(() => import("../components/compliance/Kyc"));

const BankVerification = lazy(() =>
	import("../components/compliance/BankVerification")
);

const TermDeposits = lazy(() =>
	import("../components/operations/TermDeposits")
);

const TermDepositSettings = lazy(() =>
	import("../components/system_config/TermDepositSettings")
);

const ManageDeletedUsers = lazy(() =>
	import("../components/system_config/ManageDeletedUsers")
);

const ManageArchivedUsers = lazy(() =>
	import("../components/system_config/ManageArchivedUsers")
);

const GeneralSettings = lazy(() =>
	import("../components/system_config/GeneralSettings")
);

const LmaxSettings = lazy(() =>
	import("../components/system_config/LmaxSettings")
);

const LpDirecting = lazy(() =>
	import("../components/system_config/LpDirecting")
);

const AuditLogs = lazy(() =>
	import("../components/system_config/AuditLogs")
);
const WalletBalancesCold = lazy(() =>
	import("../components/dashboard/WalletBalancesCold")
);

const UserFiatRefunds = lazy(() =>
	import("../components/operations/UserFiatRefunds")
);

const TradeCurrentPositions = lazy(() =>
	import("../components/operations/TradeCurrentPositions")
);

const AutoReconBankTransactions = lazy(() =>
	import("../components/auto_recon/BankTransactions")
);

const FiatTransactionsReconciliation = lazy(() =>
	import("../components/auto_recon/FiatReconciliation")
);

const AutoReconTradeAnalysis = lazy(() =>
	import("../components/auto_recon/TradeAnalysis")
);
const PlatformMetrics = lazy(() =>
	import("../components/auto_recon/PlatformMetrics")
);
const ComplianceMetrics = lazy(() =>
	import("../components/auto_recon/ComplianceMetrics")
);
const ApplicationMetrics = lazy(() =>
	import("../components/auto_recon/ApplicationMetrics")
);
const WalletLookup = lazy(() =>
	import("../components/compliance/WalletLookup")
);

const DuplicateWalletAddresses = lazy(() =>
	import("../components/compliance/DuplicateWalletAddresses")
);

const Referrers = lazy(() =>
	import("../components/affiliates/Referrers")
);

const OandaReporting = lazy(() =>
	import("../components/auto_recon/OandaReporting")
);

const B2bVsB2C = lazy(() =>
	import("../components/auto_recon/B2BVsB2C")
);

const AutoSweeping = lazy(() =>
	import("../components/liquidity/AutoSweeping")
);

const routes = [
	{
		path: URLS.HOME,
		exact: true,
		private_route: false,
		component: Login,
	},
	{
		path: URLS.AUTH_2FA,
		exact: true,
		private_route: false,
		component: Auth2fa,
	},
	{
		path: URLS.COMPLETE_COGNITO,
		exact: true,
		private_route: false,
		component: CompleteCognito,
	},
	{
		path: URLS.ADMIN,
		exact: true,
		private_route: true,
		title: "Dashboard",
		component: Dashboard,
	},
	{
		path: URLS.DASHBOARD.POSITIONS_WINTERMUTE,
		exact: true,
		private_route: true,
		title: "Positions - Wintermute",
		component: PositionsWinterMute,
	},
	{
		path: URLS.DASHBOARD.WALLET_BALANCES_COLD,
		exact: true,
		private_route: true,
		title: "Wallet Balances Cold",
		component: WalletBalancesCold,
	},
	{
		path: URLS.DASHBOARD.POSITIONS_PAXOS,
		exact: true,
		private_route: true,
		title: "Positions - Paxos",
		component: PositionsPaxos,
	},
	{
		path: URLS.PROFILE,
		exact: true,
		private_route: true,
		title: "Edit Profile",
		component: Profile,
	},
	{
		path: URLS.TRANCTIONS,
		exact: true,
		private_route: true,
		title: "Transactions",
		component: TransactionReports,
	},
	{
		path: URLS.LIMIT_ORDERS,
		exact: true,
		private_route: true,
		title: "FIX Orders",
		component: LimitOrders,
	},
	{
		path: `${URLS.LEGACY}/:id`,
		exact: true,
		private_route: true,
		title: {
			"1": "Manage Buy Request",
			"2": "Manage Sell Request"
		},
		component: Legacy,
	},
	{
		path: `${URLS.CASH_WITHDRAWS}`,
		exact: true,
		private_route: true,
		title: "User Withdraw",
		component: CashWithdraws,
	},
	{
		path: `${URLS.USER_SESSIONS}/SignUp`,
		exact: true,
		private_route: true,
		title: "User Sessions",
		component: UserSessions,
	},
	{
		path: `${URLS.USER_SESSIONS}/SignIn`,
		exact: true,
		private_route: true,
		title: "User Sessions",
		component: UserSessions,
	},
	{
		path: `${URLS.USER_SESSIONS}/ForgotPassword`,
		exact: true,
		private_route: true,
		title: "User Sessions",
		component: UserSessions,
	},
	{
		path: URLS.USER_IP_WHITELIST,
		exact: true,
		private_route: true,
		title: "Manage User IP Whitelisting",
		component: UserIPWhitelist,
	},
	{
		path: URLS.VENDORS,
		exact: true,
		private_route: true,
		title: "Manage Vendors",
		component: Vendors,
	},
	{
		path: URLS.CASH_DEPOSITS,
		exact: true,
		private_route: true,
		title: "Deposit Fiat History",
		component: CashDeposits,
	},
	{
		path: URLS.REPORTS,
		exact: true,
		private_route: true,
		title: "Reports",
		component: Reports,
	},
	{
		path: URLS.CRYPTO_WITHDRAWALS,
		exact: true,
		private_route: true,
		title: "Crypto Withdrawal",
		component: CryptoWithdraws,
	},
	{
		path: URLS.CRYPTO_DEPOSITS,
		exact: true,
		private_route: true,
		title: "Crypto Deposits",
		component: CryptoDeposits,
	},

	{
		path: URLS.CURRENCY,
		exact: true,
		private_route: true,
		title: "Manage Currency",
		component: Currency,
	},
	{
		path: URLS.USER_FIAT_HISTORY,
		exact: true,
		private_route: true,
		title: "Manage User Fiat History",
		component: UserFiatHistory,
	},
	{
		path: URLS.WITHDRAW_PENDINGS,
		exact: true,
		private_route: true,
		title: "User Withdraw Pending",
		component: FiatWithdrawPending,
	},
	{
		path: URLS.CRYPTO_WITHDRAW_PENDINGS,
		exact: true,
		private_route: true,
		title: "Pending Crypto Withdrawal",
		component: CryptoWithdrawPending,
	},
	{
		path: URLS.CASH_DEPOSITS_PENDINGS,
		exact: true,
		private_route: true,
		title: "Manage Pending Fiat Deposits",
		component: CashDepositsPending,
	},
	{
		path: URLS.CRYPTO_WITHDRAW_DECLINED,
		exact: true,
		private_route: true,
		title: "User Withdraw Declined",
		component: CryptoWithdrawDeclined,
	},
	{
		path: URLS.CRYPTO_WITHDRAW_REFUND,
		exact: true,
		private_route: true,
		title: "Crypto Transactions",
		component: CryptoWithdrawRefund,
	},
	{
		path: URLS.CASH_WITHDRAW_REFUND,
		exact: true,
		private_route: true,
		title: "Fiat Withdraw Transactions",
		component: FiatWithdrawRefund,
	},
	{
		path: URLS.API_SETTINGS,
		exact: true,
		private_route: true,
		title: "API Settings",
		component: ApiSettings,
	},
	{
		path: URLS.API_SETTINGS_TOKENS,
		exact: true,
		private_route: true,
		title: "Manage API Tokens",
		component: ApiSettingsToken,
	},
	{
		path: URLS.MOBILE_SETTINGS,
		exact: true,
		private_route: true,
		title: "Mobile Settings",
		component: MobileSettings,
	},
	{
		path: URLS.COIN,
		exact: true,
		private_route: true,
		title: "Manage Coins",
		component: Coin,
	},
	{
		path: URLS.COIN_GROUP,
		exact: true,
		private_route: true,
		title: "Manage Coins Groups",
		component: CoinGroup,
	},
	{
		path: URLS.AFFILIATES.PAYOUT,
		exact: true,
		private_route: true,
		title: "User Affiliate Payout Requests",
		component: AffiliatesPayouts,
	},
	{
		path: URLS.AFFILIATES.REFERRAL_EARNING_TRANSACTION,
		exact: true,
		private_route: true,
		title: "Earnings Per Transactions",
		component: EarningPerTransaction,
	},
	{
		path: URLS.AFFILIATES.REFERRAL_LINKS,
		exact: true,
		private_route: true,
		title: "Referral Links",
		component: ReferralLinks,
	},
	{
		path: URLS.AFFILIATES.REFERRAL_CODES,
		exact: true,
		private_route: true,
		title: "Manage Referral Codes",
		component: ReferralCodes,
	},
	{
		path: URLS.AFFILIATES.REFERRAL_EARNING,
		exact: true,
		private_route: true,
		title: "Earnings Per User",
		component: EarningPerUser,
	},
	{
		path: URLS.AFFILIATES.REFERRAL_COMMISSION,
		exact: true,
		private_route: true,
		title: "Manage Referral Commissions",
		component: ReferralCommission,
	},
	{
		path: URLS.AFFILIATES.USER_AFFILIATE_STATUS,
		exact: true,
		private_route: true,
		title: "User Affiliate Codes",
		component: UserAffiliateStatus,
	},
	{
		path: URLS.AFFILIATES.STATIC_AFFILIATE_STATUS,
		exact: true,
		private_route: true,
		title: "Static Affiliate Status",
		component: StaticAffiliateStatus,
	},
	{
		path: URLS.AFFILIATES.VANITY_URL,
		exact: true,
		private_route: true,
		title: "Vanity URL",
		component: VanityUrl,
	},
	{
		path: URLS.USERS,
		exact: true,
		private_route: true,
		title: "Manage Users",
		component: Users,
	},

	{
		path: URLS.SYSTEM_CONFIG.ACCOUNT_TIERS,
		exact: true,
		private_route: true,
		title: "Fee Tiers",
		component: AccountTiers,
	},

	{
		path: URLS.SYSTEM_CONFIG.MANAGE_WALLET_ADDRESS,
		exact: true,
		private_route: true,
		title: "Manage Wallet Address",
		component: ManageWalletAddress,
	},
	{
		path: URLS.SYSTEM_CONFIG.MANAGE_DEPOSIT_WALLET_ADDRESS,
		exact: true,
		private_route: true,
		title: "Manage Wallet Address",
		component: ManageDepositWalletAddress,
	},
	{
		path: URLS.SYSTEM_CONFIG.MANAGE_VIRTUAL_IBAN,
		exact: true,
		private_route: true,
		title: "Manage User Virtual IBAN",
		component: VirtualIBAN,
	},
	{
		path: URLS.SYSTEM_CONFIG.LP_DIRECTING,
		exact: true,
		private_route: true,
		title: "LP Directing",
		component: LpDirecting,
	},

	{
		path: URLS.SYSTEM_CONFIG.AUDIT_LOGS,
		exact: true,
		private_route: true,
		title: "Audit Logs",
		component: AuditLogs,
	},

	{
		path: URLS.USER_CONFIG.FEES,
		exact: true,
		private_route: true,
		title: "Fee Codes",
		component: Fees,
	},

	{
		path: URLS.USER_CONFIG.CUSTOM_EMAIL,
		exact: true,
		private_route: true,
		title: "Custom Email",
		component: CustomEmail,
	},

	{
		path: URLS.USER_CONFIG.USER_EVENT_EMAILS,
		exact: true,
		private_route: true,
		title: "User Event Emails",
		component: UserEventEmails,
	},

	{
		path: `${URLS.AUTO_TRADE}/buy`,
		exact: true,
		private_route: true,
		title: "Order Trade Buy",
		component: AutoTrade,
	},

	{
		path: `${URLS.AUTO_TRADE}/sell`,
		exact: true,
		private_route: true,
		title: "Order Trade Sell",
		component: AutoTrade,
	},

	{
		path: `${URLS.AUTO_TRADE}`,
		exact: true,
		private_route: true,
		title: "Order Trade",
		component: AutoTrade,
	},


	{
		path: `${URLS.COMPLIANCE.MANAGE_COMPANIES}`,
		exact: true,
		private_route: true,
		title: "Manage Businesses",
		component: ManageCompanies,
	},
	{
		path: `${URLS.COMPLIANCE.COMPLIANCE_REPORT}`,
		exact: true,
		private_route: true,
		title: "Compliance Report",
		component: ComplianceReport,
	},
	{
		path: `${URLS.COMPLIANCE.KYT_REJECTED}`,
		exact: true,
		private_route: true,
		title: "KYT Rejected",
		component: ComplianceKytRejected,
	},
	{
		path: `${URLS.COMPLIANCE.B2B}`,
		exact: true,
		private_route: true,
		title: "B2B",
		component: ComplianceB2b,
	},
	{
		path: `${URLS.COMPLIANCE.OVER60_COUNTDOWN}`,
		exact: true,
		private_route: true,
		title: "Over60 Countdown",
		component: ComplianceOver60Countdown,
	},
	{
		path: `${URLS.COMPLIANCE.TRAVEL_RULE}`,
		exact: true,
		private_route: true,
		title: "Transaction Monitoring",
		component: ComplianceTravelRule,
	},
	{
		path: `${URLS.USER_CONFIG.WALLET_ADDRESS_GENERATE}`,
		exact: true,
		private_route: true,
		title: "Wallet Address Generate",
		component: WalletAddressGenerate,
	},
	{
		path: `${URLS.COMPLIANCE.KYC_BILLINGS}`,
		exact: true,
		private_route: true,
		title: "KYC Billings",
		component: KycBillings,
	},
	{
		path: `${URLS.COMPLIANCE.EDD}`,
		exact: true,
		private_route: true,
		title: "EDD",
		component: Edd,
	},

	{
		path: `${URLS.COMPLIANCE.KYC}`,
		exact: true,
		private_route: true,
		title: "Manage KYC",
		component: Kyc,
	},

	{
		path: `${URLS.COMPLIANCE.BANK_VERIFICATION}`,
		exact: true,
		private_route: true,
		title: "Bank Verification",
		component: BankVerification,
	},
	{
		path: `${URLS.SYSTEM_CONFIG.TERM_DEPOSIT_SETTINGS}`,
		exact: true,
		private_route: true,
		title: "Term Deposit Settings",
		component: TermDepositSettings,
	},
	{
		path: `${URLS.OPERATION.TERM_DEPOSITS}`,
		exact: true,
		private_route: true,
		title: "Term Deposits",
		component: TermDeposits,
	},

	{
		path: `${URLS.SYSTEM_CONFIG.MANAGE_DELETED_USERS}`,
		exact: true,
		private_route: true,
		title: "Manage Deleted Users",
		component: ManageDeletedUsers,
	},

	{
		path: `${URLS.SYSTEM_CONFIG.MANAGE_ARCHIVED_USERS}`,
		exact: true,
		private_route: true,
		title: "Manage Archived Users",
		component: ManageArchivedUsers,
	},

	{
		path: `${URLS.SYSTEM_CONFIG.GENERAL_SETTINGS}`,
		exact: true,
		private_route: true,
		title: "General Settings",
		component: GeneralSettings,
	},

	{
		path: `${URLS.COMPLIANCE.WALLET_LOOKUP}`,
		exact: true,
		private_route: true,
		title: "Wallet Lookup",
		component: WalletLookup,
	},
	{
		path: `${URLS.COMPLIANCE.DUPLICATE_WALLET_ADDRESSES}`,
		exact: true,
		private_route: true,
		title: "Duplciate Wallet Addresses",
		component: DuplicateWalletAddresses,
	},
	{
		path: `${URLS.COMPLIANCE.CLIENT_VALUATION_STATEMENT}`,
		exact: true,
		private_route: true,
		title: "Client Valuation Statement",
		component: ClientValuationStatement,
	},
	{
		path: URLS.COMPLIANCE.RESTRICTED_USER_SESSIONS,
		exact: true,
		private_route: true,
		title: "Login - User Sessions - Restricted",
		component: RestrictedUserSessions
	},
	{
		path: `${URLS.SYSTEM_CONFIG.LMAX_SETTINGS}`,
		exact: true,
		private_route: true,
		title: "Lmax Settings",
		component: LmaxSettings,
	},

	{
		path: `${URLS.OPERATION.USER_FIAT_REFUNDS}`,
		exact: true,
		private_route: true,
		title: "User Fiat Refund",
		component: UserFiatRefunds,
	},

	{
		path: `${URLS.OPERATION.TRADE_CURRENT_POSITIONS}`,
		exact: true,
		private_route: true,
		title: "Trade - Current Positions",
		component: TradeCurrentPositions,
	},

	{
		path: `${URLS.AUTO_RECON.UPLOAD_BANK_TRANSACTIONS}`,
		exact: true,
		private_route: true,
		title: "Upload Bank Transactions",
		component: AutoReconBankTransactions,
	},

	{
		path: `${URLS.AUTO_RECON.FIAT_TRANSACTIONS_RECONCILIATION}`,
		exact: true,
		private_route: true,
		title: "Fiat Transactions Reconciliation",
		component: FiatTransactionsReconciliation,
	},

	// {
	// 	path: `${URLS.AUTO_RECON.FIREBLOCKS_RECONCILIATION}`,
	// 	exact: true,
	// 	private_route: true,
	// 	title: "Crypto Deposit & Withdraw Reconciliation",
	// 	component: FireblocksReconciliation,
	// },

	{
		path: `${URLS.AUTO_RECON.TRADE_ANALYSIS}`,
		exact: true,
		private_route: true,
		title: "Trade Analysis",
		component: AutoReconTradeAnalysis,
	},
	{
		path: `${URLS.AUTO_RECON.PLATFORM_METRICS}`,
		exact: true,
		private_route: true,
		title: "Platform Metrics",
		component: PlatformMetrics,
	},
	{
		path: `${URLS.AUTO_RECON.COMPLIANCE_METRICS}`,
		exact: true,
		private_route: true,
		title: "Compliance Metrics",
		component: ComplianceMetrics,
	},
	{
		path: `${URLS.AUTO_RECON.APPLICATION_METRICS}`,
		exact: true,
		private_route: true,
		title: "Application Metrics",
		component: ApplicationMetrics,
	},

	{
		path: `${URLS.AFFILIATES.REFERRERS}`,
		exact: true,
		private_route: true,
		title: "Referrers List",
		component: Referrers,
	},
	{
		path: `${URLS.AUTO_RECON.OANDA_REPORTING}`,
		exact: true,
		private_route: true,
		title: "Oanda Reporting (B2B)",
		component: OandaReporting,
	},
	{
		path: `${URLS.AUTO_RECON.B2B_VS_B2C}`,
		exact: true,
		private_route: true,
		title: "B2B vs B2C",
		component: B2bVsB2C,
	},
	{
		path: `${URLS.LIQUIDITY.AUTO_SWEEPING}`,
		exact: true,
		private_route: true,
		title: "Auto Sweeping",
		component: AutoSweeping,
	},
	{
		path: `${URLS.AUTO_RECON.PERFORMANCE_METRICS}`,
		exact: true,
		private_route: true,
		title: "Performance Metrics",
		component: PerformanceMetrics,
	},
	{
		path: `${URLS.OPERATION.FAILED_STRIPE_DEPOSITS}`,
		exact: true,
		private_route: true,
		title: "Failed Stripe Deposits",
		component: FailedStripeDeposits,	
	},
	{
		path: `${URLS.SYSTEM_CONFIG.SPREAD_GROUPS}`,
		exact: true,
		private_route: true,
		title: "Spread Groups",
		component: SpreadGroups,	
	}
];

export default routes;
